<template>
  <div>
    <!-- Header Menu -->
    <v-container
      ref="header"
      class="d-flex pa-0 justify-space-between align-center pb-1"
    >
      <v-text-field
        ref="searchInput"
        :hide-details="true"
        dense
        filled
        type="text"
        rounded
        prepend-inner-icon="search"
        v-model="searchProductQuery"
      ></v-text-field>
      <div style="width: 160px;" class="d-flex align-center">
        <v-badge dot offset-x="15" offset-y="15" :value="filters.active">
          <v-btn icon large @click="dialogFilter">
            <v-icon>filter_list</v-icon>
          </v-btn>
        </v-badge>
        <v-btn icon large @click="dialogQuantityProduct = true" class="mr-1">
          <span
            style="position: absolute;top: 3px;font-size: 13px"
            :style="{
              left: styleTextQuantityBtnLeft,
              top: quantityProductsAdd > 9 ? '4px' : '2px',
              fontSize: quantityProductsAdd > 9 ? '11px' : '13px'
            }"
            >{{ quantityProductsAdd }}</span
          >
          <v-icon>filter_none</v-icon>
        </v-btn>
        <v-btn
          fab
          x-small
          color="success"
          elevation="2"
          @click="$router.push({ name: 'register' })"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-container>
    <!-- List Products -->
    <v-card
      height="170"
      v-if="resultQuery.length === 0"
      class="mt-2 card_background d-flex justify-center flex-column"
    >
      <v-container class="text-center">
        <p class="ma-0 text-h6">🧐</p>
        <p class="caption mb-1">
          Al parecer no tienes productos registrados que coincidan con tu
          búsqueda, puedes registrar nuevos productos haciendo clic en el
          siguiente botón
        </p>
        <v-btn to="/registro-producto" small color="primary"
          >Registrar productos</v-btn
        >
      </v-container>
    </v-card>
    <v-virtual-scroll
      v-else
      :items="resultQuery"
      :item-height="90"
      :height="listRenderHeight"
      class="pa-2"
    >
      <template v-slot="{ item }">
        <v-container class="px-1" v-if="loaderProducts">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </v-container>
        <v-card
          height="85px"
          class="card_background mx-1 no-select"
          :elevation="showOnlyBy(item) ? 3 : 0"
          :disabled="!showOnlyBy(item)"
          :color="showOnlyBy(item) ? '' : 'darken-1'"
          v-else-if="productList.length > 0"
        >
          <v-btn
            absolute
            right
            icon
            small
            class="float-btn-detail"
            @click.stop="viewDetail(item)"
            ><v-icon size="17">open_in_new</v-icon></v-btn
          >
          <v-list-item @click="addToCart(item)">
            <v-list-item-content class="py-2">
              <v-list-item-subtitle>
                <span class="font-weight-bold">{{ item.product_id }}</span>
              </v-list-item-subtitle>
              <v-list-item-title>{{ item.name_product }} </v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ item.description }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="d-flex justify-space-between">
                <div>Precio $ {{ item.price }}</div>
                <span>{{ item.total_inventory | nameProductParts }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card class="align-center d-flex justify-center" height="68vh" v-else>
          <div class="px-5 py-5 ">
            <div class="text-subtitle-2 text-center mb-2">
              Registrar productos y comienza a vender 👍
            </div>
            <div class="text-center">
              <v-btn color="primary" @click="$router.push({ name: 'register' })"
                >registrar productos</v-btn
              >
            </div>
          </div>
        </v-card>
      </template>
    </v-virtual-scroll>
    <!-- Dialogs -->
    <v-dialog v-model="dialogQuantityProduct" max-width="260">
      <v-card class="card_background">
        <v-container>
          <div class="d-flex align-start justify-space-between">
            <h3 class="text-h5 pb-1 pt-2">Cantidad</h3>
            <v-btn icon @click="dialogQuantityProduct = false"
              ><v-icon>close</v-icon></v-btn
            >
          </div>

          <v-divider></v-divider>
          <p class="caption mb-0">
            Seleccione las unidades por las que quiere multiplicar su proxima
            venta.
          </p>
          <v-container class="justify-center d-flex">
            <div class="d-flex align-center justify-center">
              <v-btn icon @click="subtractQuantityProductsAdd()">
                <v-icon>remove</v-icon>
              </v-btn>
              <v-text-field
                class="centered-input"
                v-model="quantityProductsAdd"
                style="width:90px"
                hide-details
                outlined
                dense
                type="number"
              ></v-text-field>
              <v-btn icon @click="quantityProductsAdd++">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </v-container>
          <v-btn block color="primary" @click="dialogQuantityProduct = false"
            >Guardar</v-btn
          >
        </v-container>
      </v-card>
    </v-dialog>
    <app-filter-list></app-filter-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FilterList from "./FilterList";
import { toggleDialogFilter, toggleDialogDetail } from "@/services/DialogsBus";

export default {
  data() {
    return {
      searchProductQuery: null,
      quantityProductsAdd: 1,
      dialogQuantityProduct: false,
      filterBy: false
    };
  },
  computed: {
    ...mapState("pointSale", [
      "productList",
      "loaderProducts",
      "currentProductSelected",
      "filters"
    ]),
    // :: Query for search
    resultQuery() {
      if (this.searchProductQuery) {
        return this.productList.filter(product => {
          return this.searchProductQuery
            .toLowerCase()
            .split(" ")
            .every(v => product.name_product.toLowerCase().includes(v));
        });
      } else {
        return this.productList;
      }
    },
    listRenderHeight() {
      const contentView = 56 + 100 + 48 + 20;
      return this.$vuetify.breakpoint.height - contentView;
    },
    styleTextQuantityBtnLeft() {
      if (this.quantityProductsAdd > 9) return "17px";
      if (this.quantityProductsAdd == 1) return "21px";
      else return "19px";
    }
  },
  watch: {
    // :: Total Products Add
    quantityProductsAdd(newValue) {
      if (newValue <= 0) this.quantityProductsAdd = 1;
    }
  },
  methods: {
    ...mapActions("pointSale", [
      "showCurrentProductSelected",
      "showSellAlert",
      "addProductToCart",
      "viewDetailProduct"
    ]),
    // :: Push new product to cart
    addToCart(product) {
      this.showSellAnimation();
      this.showCurrentProductSelected(product);
      this.addProductToCart({ data: product, items: this.quantityProductsAdd });
    },
    viewDetail(product) {
      this.viewDetailProduct(product);
      toggleDialogDetail();
    },
    showSellAnimation() {
      this.showSellAlert(true);
      setTimeout(() => {
        this.showSellAlert(false);
      }, 1500);
    },
    subtractQuantityProductsAdd() {
      if (this.quantityProductsAdd > 0) {
        this.quantityProductsAdd--;
      }
    },
    dialogFilter() {
      toggleDialogFilter();
    },
    showOnlyBy(product) {
      let total_inventory = true;
      let wholesale = true;
      let discount = true;
      if (!this.filters.showOnlyBy.includes(0)) {
        this.filters.showOnlyBy.forEach(element => {
          switch (element) {
            case 1:
              if (product.total_inventory > 0) total_inventory = true;
              else total_inventory = false;
              break;
            case 2:
              if (product.wholesale) wholesale = true;
              else wholesale = false;
              break;
            case 3:
              if (product.discount) discount = true;
              else discount = false;
              break;
          }
        });
        return total_inventory && wholesale && discount;
      } else {
        return true;
      }
    }
  },
  filters: {
    nameProductParts(parts) {
      if (parts != null) {
        if (parts === 1) return parts + " Pieza";
        else return parts + " Piezas";
      } else {
        return "N/A";
      }
    }
  },
  components: {
    AppFilterList: FilterList
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
.float-btn-detail {
  right: -2px;
  top: -3px;
  z-index: 4;
}
</style>
