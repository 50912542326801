<template>
  <div class="py-2">
    <v-slide-y-transition hide-on-leave>
      <div v-if="footerOption === 0 && !$vuetify.breakpoint.lgAndUp">
        <app-list></app-list>
        <app-dialog-detail-product></app-dialog-detail-product>
      </div>
    </v-slide-y-transition>
    <v-slide-y-transition hide-on-leave>
      <div v-show="footerOption === 1 && !$vuetify.breakpoint.lgAndUp">
        <app-video></app-video>

        <template v-if="footerOption === 1">
          <v-container
            v-if="getCurrentCustomer.shoppingCartProductList.length === 0"
            class="text-center caption mt-8"
          >
            No tienes ningún en el carrito
          </v-container>
          <app-list-bar-code
            v-else
            :windowHeight="windowHeight"
            :productList="getCurrentCustomer.shoppingCartProductList"
          ></app-list-bar-code>
          <app-dialog-edit-product></app-dialog-edit-product>
          <app-dialog-detail-product></app-dialog-detail-product>
        </template>
      </div>
    </v-slide-y-transition>
    <v-slide-y-transition hide-on-leave>
      <template v-if="footerOption === 2 && !$vuetify.breakpoint.lgAndUp">
        <app-customers :windowHeight="windowHeight"></app-customers>
      </template>
    </v-slide-y-transition>
    <v-slide-y-transition hide-on-leave>
      <div v-if="footerOption === 3 && !$vuetify.breakpoint.lgAndUp">
        <app-shopping-cart></app-shopping-cart>
        <app-dialog-edit-product></app-dialog-edit-product>
      </div>
    </v-slide-y-transition>
    <app-sale-status-btn v-if="footerOption === 0 || footerOption === 1" />
    <app-list-footer-navegation
      v-if="!$vuetify.breakpoint.lgAndUp"
    ></app-list-footer-navegation>
    <app-point-sales-desktop
      v-if="$vuetify.breakpoint.lgAndUp"
    ></app-point-sales-desktop>
    <v-dialog v-model="dialogExit" max-width="290">
      <v-card class="py-2">
        <v-card-title class="text-subtitle-2">
          <p class="ma-0">Estás a punto de salir del punto de venta 👋</p>
        </v-card-title>
        <v-card-text class="caption"
          >Debido a que esta vista maneja grandes cantidades de datos,
          necesitamos confirmar que estás seguro de abandonar esta
          vista.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelExitRoute()">
            Cancelar
          </v-btn>
          <v-btn color="success" @click="confirmExitRoute()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

// :: Components
import List from "../components/point-of-sale/list/ListNormal";
import FooterNavegation from "../components/point-of-sale/FooterNavegation";
import SaleStatusBtn from "../components/point-of-sale/SaleStatusBtn";
import Video from "../components/point-of-sale/barcode/Video";
import ListBarCode from "../components/point-of-sale/barcode/ListBarCode";
import Customers from "../components/point-of-sale/customers/Customers";
import ShoppingCart from "../components/point-of-sale/cart/ShoppingCart";
import ContentView from "../components/point-of-sale/desktop/ContentView";
import DialogDetailProduct from "../components/point-of-sale/DialogDetailProduct";
import DialogEditProduct from "../components/point-of-sale/DialogEditProduct";
import { database } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";

export default {
  name: "pointSale",
  data() {
    return {
      productDatabaseRef: null,
      dialogExit: false,
      dialogResolve: null
    };
  },
  computed: {
    ...mapState("pointSale", [
      "footerOption",
      "showSellProduct",
      "shoppingCartProducts"
    ]),
    ...mapGetters("pointSale", ["getCurrentCustomer"]),
    ...mapState("user", ["data"]),
    windowHeight() {
      return this.$vuetify.breakpoint.height;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.dialogExitRoute().then(confirmResult => next(confirmResult));
  },
  beforeDestroy() {
    this.removeListenerDatabase();
    this.showSellAlert(false);
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    ...mapActions("pointSale", [
      "saveProductsList",
      "showSellAlert",
      "activateLoaderProductList"
    ]),
    async getProducts() {
      try {
        this.activateLoaderProductList(true);
        this.productDatabaseRef = await database(`store`);
        this.productDatabaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            const products = snapshot.val();
            const sales = Object.keys(products).map(key => products[key]);
            this.saveProductsList(sales);
          } else {
            this.saveProductsList([]);
          }
        });
      } catch (error) {
        this.activateLoaderProductList(false);
        errorMessage({
          message: "No pudimos obtener los productos, Code: " + error
        });
      }
    },
    async removeListenerDatabase() {
      await this.productDatabaseRef.off("value");
      this.productDatabaseRef = null;
    },
    dialogExitRoute() {
      this.dialogExit = true;
      return new Promise(resolve => {
        this.dialogResolve = resolve;
      });
    },
    cancelExitRoute() {
      this.dialogExit = false;
      this.dialogResolve(false);
    },
    confirmExitRoute() {
      this.dialogExit = false;
      this.dialogResolve(true);
    }
  },
  components: {
    AppList: List,
    AppListFooterNavegation: FooterNavegation,
    AppSaleStatusBtn: SaleStatusBtn,
    AppVideo: Video,
    AppListBarCode: ListBarCode,
    AppCustomers: Customers,
    AppShoppingCart: ShoppingCart,
    AppPointSalesDesktop: ContentView,
    AppDialogDetailProduct: DialogDetailProduct,
    AppDialogEditProduct: DialogEditProduct
  }
};
</script>

<style></style>
