<template>
  <div>
    <v-expansion-panels :value="actualCustomerIndex">
      <v-expansion-panel
        v-for="(customer, index) in customersList"
        :key="index + '-customer'"
        :id="customer.id"
        color="info"
        :class="{ 'blue-grey darken-2': actualCustomerIndex == index }"
      >
        <v-expansion-panel-header class="py-2">
          <div :class="{ 'white--text': actualCustomerIndex == index }">
            <p class="ma-0 ">{{ customer.name }}</p>
            <p class="ma-0 caption">
              Total en venta: $ {{ customer.totalToPay }}
            </p>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="change-paddin-content">
          <v-container
            class="py-1 d-flex justify-space-between align-center"
            :class="{
              'background-actions': actualCustomerIndex == index,
              'background-actions-ligth': actualCustomerIndex != index
            }"
          >
            <v-btn small color="success" @click="changeCurrenCustomerID(index)"
              >Seleccionar</v-btn
            >
            <v-btn
              icon
              color="error"
              @click="deleteCustomerMethod(customer.id, customer.name)"
              ><v-icon>delete</v-icon></v-btn
            >
          </v-container>
          <v-list-item
            v-for="(product, indexChild) in customer.shoppingCartProductList"
            :key="indexChild + '-sale-child'"
            dense
            :dark="actualCustomerIndex === index"
          >
            <v-list-item-content>
              <v-list-item-title> {{ product.name_product }}</v-list-item-title>
              <v-list-item-subtitle>{{
                product.total_products | nameProductParts
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="text-right max-width-price">
              <v-list-item-title>$ {{ product.total_price }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div
            v-if="customer.shoppingCartProductList.length === 0"
            :class="{ 'white--text': actualCustomerIndex == index }"
            class="px-10 text-center py-4 caption"
          >
            Este Ticket no tiene productos listados, agrege productos y se
            listaran aquí <br />
            😉
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="dialogDeleteCustomer" persistent max-width="290">
      <v-card>
        <v-card-title class="text-subtitle-2">
          Estás seguro de eliminar el
          <span class="font-weight-black">{{ localCustomerDeleteName }}</span> ?
        </v-card-title>
        <v-card-text class="caption"
          >Ten en cuenta que se perderán todos los productos almacenados en este
          ticket</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDeleteCustomer = false">
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            @click="
              deleteCustomer(localCustomerDeleteID);
              dialogDeleteCustomer = false;
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      dialogDeleteCustomer: false,
      localCustomerDeleteID: null,
      localCustomerDeleteName: "",
      activemodel: 0
    };
  },
  mounted() {
    let id = this.actualCustomerID;
    const height = this.$vuetify.breakpoint.height / 2.5;
    let target = document.getElementById(id);
    this.$vuetify.goTo(target, {
      offset: height
    });
  },
  computed: {
    ...mapState("pointSale", [
      "customersList",
      "actualCustomerIndex",
      "actualCustomerID"
    ]),
    ...mapGetters("pointSale", ["getCustomerPositionIndex"])
  },
  methods: {
    ...mapActions("pointSale", ["deleteCustomer", "changeCurrenCustomerID"]),
    deleteCustomerMethod(id, name) {
      this.dialogDeleteCustomer = true;
      this.localCustomerDeleteID = id;
      this.localCustomerDeleteName = name;
    }
  },
  filters: {
    nameProductParts(parts) {
      if (parts == 1) return parts + " Pieza";
      else return parts + " Piezas";
    }
  }
};
</script>

<style scoped>
.background-actions {
  background: #3f535d !important;
}
.background-actions-ligth {
  background: #e4e4e4 !important;
}
</style>
<style>
.change-paddin-content > .v-expansion-panel-content__wrap {
  padding: 0 8px !important;
}
</style>
