<template>
  <v-row>
    <v-card width="100%" class="card_background" :elevation="elevationCard">
      <div
        class="text-h6 pt-4 pl-3 d-flex justify-space-between"
        v-if="hideTitle"
      >
        Lista de Productos
        <v-btn icon class="pb-2" color="error" @click="dialogResetCart = true"
          ><v-icon>delete</v-icon></v-btn
        >
      </div>
      <v-divider v-if="hideTitle"></v-divider>
      <v-container v-if="productList.length === 0" class="text-center caption">
        Ningún producto listado
      </v-container>
      <template v-for="(product, index) in productList" v-else>
        <v-container :key="product.id + '-cart'" class="py-1">
          <v-card outlined @click="openDialogEditProduct(product, index)">
            <v-list-item class="no-select px-6">
              <v-list-item-content class="py-2">
                <v-list-item-subtitle class="text--secondary text-body-2">
                  {{ product.id }}
                </v-list-item-subtitle>
                <v-list-item-title class="text-subtitle-1"
                  >{{ product.name_product }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-body-1 ma-0">
                  <span
                    v-if="product.wholesale"
                    v-html="
                      priceWithWholesale(
                        product.price_by_unit,
                        product.wholesale.wholesale_price
                      )
                    "
                  ></span>
                  <span v-else>{{
                    product.price_by_unit | priceWithoutWholesale
                  }}</span>
                  <span class="caption ml-1">x producto</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text--secondary text-body-2">{{
                  product.total_products | nameProductParts
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="text-right max-width-price">
                <p class="ma-0 caption">Total</p>
                <v-list-item-title class="mb-0 font-weight-bold"
                  >$ {{ product.total_price }}</v-list-item-title
                >
                <div
                  class="text-subtitle-2 text--disabled text-decoration-line-through"
                  v-if="product.subtotal_price > product.total_price"
                >
                  $ {{ product.subtotal_price }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
      </template>
    </v-card>
    <v-dialog v-model="dialogResetCart" persistent max-width="290">
      <v-card>
        <v-card-title class="text-subtitle-2">
          Estás seguro de vaciar el carrito del Ticket:
          <span class="font-weight-black">{{ actualCustomerID }}</span> ?
        </v-card-title>
        <v-card-text class="caption"
          >Ten en cuenta que se perderán todos los productos almacenados en este
          ticket</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogResetCart = false">
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            @click="
              deleteCustomer(actualCustomerID);
              dialogResetCart = false;
            "
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { toggleDialogEdit } from "@/services/DialogsBus";

export default {
  data: () => ({ dialogResetCart: false }),
  props: {
    productList: {
      default: () => []
    },
    hideTitle: {
      default: true
    },
    elevationCard: {
      default: null
    }
  },
  computed: {
    ...mapState("pointSale", ["actualCustomerID"])
  },
  methods: {
    ...mapActions("pointSale", ["viewEditProductInCart", "deleteCustomer"]),
    openDialogEditProduct(product, index) {
      this.viewEditProductInCart({
        product: product,
        indexProduct: index
      });
      toggleDialogEdit();
    },
    priceWithWholesale(price, wholesalePrice) {
      if (price > wholesalePrice) {
        return `$ ${wholesalePrice} <span class="text-subtitle-2 text--secondary text-decoration-line-through ml-1">$ ${price}</span>`;
      }
      return `$ ${price}`;
    }
  },
  filters: {
    nameProductParts(parts) {
      if (parts == 1) return "Total " + parts + " Pieza";
      else return "Total " + parts + " Piezas";
    },
    priceWithoutWholesale(price) {
      return `$ ${price}`;
    }
  }
};
</script>

<style>
.max-width-price {
  max-width: 90px !important;
}
</style>
