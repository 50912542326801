<template>
  <v-dialog
    v-model="dialogFilter"
    max-width="350"
    width="350"
    ref="dialogContent"
  >
    <v-card class="card_background">
      <v-app-bar
        elevation="1"
        width="326px"
        height="56"
        style="position:fixed; z-index:10; transform: translateX(-50%); left: 50%"
      >
        <span class="text-h6">Filtros</span>
        <v-spacer></v-spacer>

        <v-btn icon @click="dialogFilter = false"><v-icon>close</v-icon></v-btn>
      </v-app-bar>
      <div style="height: 43px"></div>
      <v-container>
        <!-- <p class="caption mb-0">
          Seleccione el filtro que desea aplicar a la lista de productos.
        </p> -->
        <v-row>
          <v-col cols="12" class="pb-1 text-center font-weight-bold">
            Ordenamiento
            <p class="caption ma-0">
              Selecciona la opción con la que quieres que tus productos se
              listen
            </p>
          </v-col>
          <v-col cols="12" class="py-1 px-0">
            <v-list-item-group
              v-model="sort_selection"
              mandatory
              @change="changeFilter($event, 0)"
            >
              <template v-for="(sort_value, i) in sort">
                <v-list-item
                  :key="`sort-${i}`"
                  active-class="primary--text"
                  style="min-height: 38px"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-1 my-1">
                      <v-switch
                        :input-value="active"
                        inset
                        dense
                        readonly
                      ></v-switch>
                    </v-list-item-action>
                    <v-list-item-content class="py-1">
                      <v-list-item-title
                        v-text="sort_value"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-col>
          <v-col cols="12" class=" py-1">
            <v-btn-toggle
              v-model="typeSort"
              @change="changeFilter($event, 3)"
              mandatory
              active-class="primary--text"
            >
              <v-btn>
                <div>
                  <p class="caption ma-0">
                    Mayor a menor
                  </p>
                  <v-icon>align_vertical_top</v-icon>
                </div>
              </v-btn>
              <v-btn>
                <div>
                  <p class="caption ma-0">
                    Menor a mayor
                  </p>
                  <v-icon>align_vertical_bottom</v-icon>
                </div>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" class="py-1 pb-2">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class=" py-1 text-center font-weight-bold">
            Deshabilitar elementos
            <p class="caption ma-0">
              Selecciona los elementos que quieres deshabilitar de la lista
            </p>
          </v-col>
          <v-col cols="12" class="px-0 py-1">
            <v-list-item-group
              v-model="show_selection"
              mandatory
              multiple
              @change="changeFilter($event, 1)"
            >
              <template v-for="(sort_value, i) in show">
                <v-list-item
                  :key="`sort-${i}`"
                  active-class="primary--text"
                  style="min-height: 38px"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-1 my-1">
                      <v-switch
                        :input-value="active"
                        inset
                        dense
                        readonly
                      ></v-switch>
                    </v-list-item-action>
                    <v-list-item-content class="py-1">
                      <v-list-item-title
                        v-text="sort_value"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-col>
          <!-- <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class=" py-1 text-center font-weight-bold">
            Rango de precios
            <p class="caption ma-0">
              Indica el rango de precios que quieres filtrar (ten en
              consideración los filtros anteriores)
            </p>
          </v-col>
          <v-col cols="12" class="px-10 py-1 pb-3">
            <v-form ref="validPrices">
              <div class="d-flex align-center">
                <v-text-field
                  class="mr-1"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="attach_money"
                  label="Min"
                  type="number"
                  :error="validPrice"
                  v-model="minPrice"
                  @input="changeFilter($event, 2)"
                ></v-text-field>
                <v-text-field
                  class="ml-1"
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="attach_money"
                  label="Max"
                  :error="validPrice"
                  type="number"
                  v-model="maxPrice"
                  @input="changeFilter($event, 2)"
                ></v-text-field>
              </div>
            </v-form>
          </v-col> -->
        </v-row>
        <v-btn block color="primary" :disabled="changed" @click="applyFilter"
          >Aplicar</v-btn
        >
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { showMessage } from "@/services/MessageService";
import { mapActions } from "vuex";
import EventBus from "@/config/bus";

export default {
  data() {
    return {
      changed: true,
      dialogFilter: false,
      typeSort: null,
      sort: [
        "Nombre del producto",
        "Descripción",
        "Precio",
        "Inventario",
        "Código de barra"
      ],
      sort_selection: 0,
      show: [
        "Ninguno",
        "Sin Inventario disponible",
        "Sin Venta por mayoreo",
        "Sin Descuento"
      ],
      show_selection: [0],
      minPrice: null,
      maxPrice: null,
      validPrice: false
    };
  },
  mounted() {
    EventBus.$on("TOGGLE_DIALOG_FILTER", () => {
      this.dialogFilter = !this.dialogFilter;
    });
  },
  methods: {
    ...mapActions("pointSale", ["filterList"]),
    changeFilter(change, section) {
      this.changed = false;
      if (section === 1) {
        const lastEl = change[change.length - 1];
        if (lastEl === 0) {
          this.show_selection.splice(0);
          this.show_selection.push(0);
          return;
        }
        if (lastEl != 0) {
          if (change.includes(0)) {
            const index = this.show_selection.findIndex(el => el === 0);
            this.show_selection.splice(index, 1);
          }
          return;
        }
      }
      // if (section === 2) {
      //   if (parseInt(this.minPrice) > parseInt(this.maxPrice)) {
      //     this.validPrice = true;
      //   } else {
      //     this.validPrice = false;
      //   }
      // }
    },
    applyFilter() {
      const filters = {
        show: [...this.show_selection],
        typeSort: this.typeSort
      };
      switch (this.sort_selection) {
        case 0:
          filters.sortBy = "name_product";
          break;
        case 1:
          filters.sortBy = "description";
          break;
        case 2:
          filters.sortBy = "price";
          break;
        case 3:
          filters.sortBy = "total_inventory";
          break;
        case 4:
          filters.sortBy = "barcode";
          break;
        default:
          filters.sortBy = "name_product";
          break;
      }
      if (this.minPrice && this.maxPrice) {
        if (parseInt(this.minPrice) > parseInt(this.maxPrice)) {
          showMessage({
            message: "El rango de precios no es correcto",
            timeout: 2000,
            color: "warning"
          });
          return;
        }
        filters.price = {
          to: this.minPrice,
          from: this.maxPrice
        };
      }
      this.dialogFilter = !this.dialogFilter;
      this.filterList(filters);
    }
  }
};
</script>

<style></style>
