<template>
  <v-dialog
    v-model="dialogProductEditInCart"
    max-width="350"
    width="350"
    persistent
  >
    <v-card class="card_background">
      <v-app-bar
        elevation="0"
        color="card_background"
        width="326px"
        height="56"
        style="position:fixed; z-index:10; transform: translateX(-50%); left: 50%"
      >
        <span class="text-h6">Revisión</span>
        <v-spacer></v-spacer>

        <v-btn icon @click="resetData"><v-icon>close</v-icon></v-btn>
      </v-app-bar>
      <div style="height: 43px"></div>
      <v-container v-if="currentEditProduct.extraMetadata">
        <div class="text-center mb-2">
          <v-img
            :src="currentEditProduct.photoURL"
            height="150"
            contain
            v-if="currentEditProduct.photoURL"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
        <v-card outlined>
          <v-container class="py-1">
            <div class="ma-0 caption">
              Código:
              <span class="text-subtitle-1">{{
                currentEditProduct.extraMetadata.barcode
              }}</span>
            </div>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1">
          <v-container class="py-1">
            <div class="ma-0 caption">Nombre</div>
            <div class="ma-0 mb-2">{{ currentEditProduct.name_product }}</div>
            <div class="ma-0 caption">Descripción</div>
            <div class="ma-0 mb-2">
              {{ currentEditProduct.extraMetadata.description }}
            </div>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1">
          <v-container class="py-1">
            <div class="d-flex justify-space-between mb-2">
              <div>
                <p class="ma-0 caption">Precio al publico</p>
                <p class="ma-0 text-h6">
                  $ {{ currentEditProduct.price_by_unit }}
                </p>
              </div>
              <div class="text-right">
                <p class="ma-0 caption">Precio costo</p>
                <p class="ma-0 text-h6">
                  $ {{ currentEditProduct.extraMetadata.cost_price }}
                </p>
              </div>
            </div>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1">
          <v-container class="py-1">
            <div>
              <p class="ma-0 caption">Inventario</p>
              <p class="ma-0">
                <span class="text-h6">{{
                  currentEditProduct.extraMetadata.total_inventory
                }}</span
                >{{
                  currentEditProduct.extraMetadata.total_inventory
                    | isInventoried
                }}
              </p>
            </div>
          </v-container>
        </v-card>
        <v-card
          outlined
          class="mt-1"
          v-if="currentEditProduct.extraMetadata.wholesales"
        >
          <v-container class="py-1">
            <div class="text-center mb-2">
              <span>Mayoreo</span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="ma-0 caption text--disabled">Precio de Mayoreo</p>
              <p class="ma-0 caption text--disabled">Min. Mayoreo</p>
            </div>
            <template
              v-for="(wholesale, index) in currentEditProduct.extraMetadata
                .wholesales"
            >
              <div
                :key="index + '-wholesale-detail'"
                class="d-flex justify-space-between mb-1"
                :class="{
                  'wholesale-selected': priceByUnit == wholesale.wholesale_price
                }"
                v-if="currentEditProduct.extraMetadata.wholesales"
              >
                <p
                  class="ma-0"
                  v-html="
                    wholesaleDiscount(
                      wholesale.wholesale_price,
                      currentEditProduct.price_by_unit
                    )
                  "
                ></p>
                <p class="ma-0">
                  <span class="font-weight-medium">{{
                    wholesale.minimum_products
                  }}</span>
                  <span class="caption">{{
                    wholesale.minimum_products | isInventoried
                  }}</span>
                </p>
              </div>
            </template>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1">
          <v-container class="py-1">
            <div class="d-flex justify-space-between mb-2 mt-2">
              <div>
                <p class="ma-0 caption text--disabled">Piezas totales</p>
                <p class="ma-0">
                  {{ totalProducts | nameProductParts }}
                </p>
              </div>
              <div class="d-flex align-end">
                <div
                  style="width: 140px"
                  class="d-flex align-center justify-end"
                >
                  <v-btn icon @click="totalProducts--">
                    <v-icon>remove</v-icon>
                  </v-btn>
                  <v-text-field
                    class="centered-input"
                    v-model="totalProducts"
                    hide-details
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                  <v-btn icon @click="totalProducts++">
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="d-flex justify-space-between mt-2">
              <div>
                <div class="ma-0 caption text--disabled">Precio total</div>
                <div class="ma-0 mb-2">
                  <span
                    v-if="totalPrice > totalPriceDiscount"
                    class="mr-2 text-h6"
                    >$ {{ totalPriceDiscount }}</span
                  >
                  <span
                    :class="{
                      'body-2 text-decoration-line-through':
                        totalPrice > totalPriceDiscount,
                      'text-h6': !(totalPrice > totalPriceDiscount)
                    }"
                    >$ {{ totalPrice }}</span
                  >
                </div>
              </div>
              <div class="text-right">
                <div class="ma-0 caption text--disabled">
                  Precio por pieza
                </div>
                <div class="ma-0 mb-2 text-h6">
                  {{ priceByUnit }}
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex justify-space-between align-center">
                <v-btn
                  class="mb-2"
                  color="error"
                  icon
                  @click="deleteProductInCartM(currentEditProduct.indexProduct)"
                  ><v-icon>delete</v-icon></v-btn
                >
                <v-btn
                  class="mb-2"
                  color="success"
                  small
                  :disabled="
                    totalProducts == currentEditProduct.total_products
                      ? true
                      : false
                  "
                  @click="
                    updatetProductInCartM(currentEditProduct.indexProduct)
                  "
                  >Actualizar</v-btn
                >
              </div>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EventBus from "@/config/bus";
export default {
  data() {
    return {
      totalProducts: 1,
      updateTotalProducts: true,
      dialogProductEditInCart: false
    };
  },
  watch: {
    currentEditProduct(newCurrent) {
      this.totalProducts = newCurrent.total_products;
    },
    totalProducts(updateTotalProducts) {
      if (updateTotalProducts < 1) {
        this.totalProducts = 1;
      }
    }
  },
  computed: {
    ...mapState("pointSale", ["currentEditProduct"]),
    totalPrice() {
      const total = this.currentEditProduct.price_by_unit * this.totalProducts;
      return parseFloat(total);
    },
    priceByUnit() {
      let currentPriceUnit = this.currentEditProduct.price_by_unit;
      if (this.currentEditProduct.extraMetadata.wholesales) {
        const wholesales = this.currentEditProduct.extraMetadata.wholesales;
        for (let i = 0; i < wholesales.length; i++) {
          const wholesale = wholesales[i];
          if (this.totalProducts >= wholesale.minimum_products) {
            currentPriceUnit = parseFloat(wholesale.wholesale_price);
          }
        }
      }
      return parseFloat(currentPriceUnit);
    },
    totalPriceDiscount() {
      let totalPriceUnit = this.currentEditProduct.price_by_unit;
      if (this.currentEditProduct.extraMetadata.wholesales) {
        const wholesales = this.currentEditProduct.extraMetadata.wholesales;
        for (let i = 0; i < wholesales.length; i++) {
          const wholesale = wholesales[i];
          if (this.totalProducts >= wholesale.minimum_products) {
            totalPriceUnit = parseFloat(wholesale.wholesale_price);
          }
        }
      }
      const total = parseFloat(totalPriceUnit * this.totalProducts);
      return total;
    }
  },
  methods: {
    ...mapActions("pointSale", [
      "viewEditProductInCart",
      "deleteProductInCart",
      "updatetProductInCart"
    ]),
    resetData() {
      this.dialogProductEditInCart = false;
      setTimeout(() => {
        this.viewEditProductInCart({
          product: {},
          indexProduct: null
        });
      }, 300);
      this.totalProducts = 1;
    },
    deleteProductInCartM(index) {
      this.deleteProductInCart(index);
      this.resetData();
    },
    updatetProductInCartM(index) {
      const totalPrice = this.totalPrice;
      const totalInventory = this.totalProducts;
      this.updatetProductInCart({ index, totalPrice, totalInventory });
      this.resetData();
    },
    wholesaleDiscount(wholesale_price, price) {
      const totalPrice = parseFloat(price);
      const wholesalePriceByUnit = parseFloat(wholesale_price);
      const wholesalePrice = wholesalePriceByUnit * 100;
      const discount = 100 - wholesalePrice / totalPrice;
      return `$ ${wholesalePriceByUnit} <span class="caption text--secondary ml-2"> ${parseFloat(
        discount
      ).toFixed(1)}% Descuento </span> `;
    }
  },
  beforeDestroy() {
    this.resetData();
  },
  mounted() {
    EventBus.$on("TOGGLE_DIALOG_EDIT_POINTSALE", () => {
      this.dialogProductEditInCart = !this.dialogProductEditInCart;
    });
  },
  filters: {
    nameProductParts(parts) {
      if (parts == 1) return parts + " Pieza";
      else return parts + " Piezas";
    },
    isInventoried(inventory) {
      if (inventory != null) {
        if (inventory === 1) return " Pieza";
        else return " Piezas";
      } else {
        return "N/A";
      }
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
.wholesale-selected {
  background-color: #e0e0e075 !important;
  border-radius: 2px;
}
</style>
