<template>
  <v-container class="pt-0 pb-0">
    <v-row>
      <v-container class="d-flex justify-space-between align-center pb-1">
        <div class="text-h5 pl-2">
          Clientes
        </div>
        <v-chip class="ma-2" color="primary">
          <v-avatar left>
            <v-icon>person</v-icon>
          </v-avatar>
          Edwin Donaldo
        </v-chip>
      </v-container>
      <v-container class="pt-0 d-flex pb-0">
        <v-text-field
          ref="searchInput"
          :hide-details="true"
          dense
          filled
          rounded
          prepend-inner-icon="search"
          @input="search()"
        ></v-text-field>
      </v-container>
      <v-container class="pt-0 pb-0">
        <app-list-sale></app-list-sale>
      </v-container>
      <v-container class="pt-0">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn dark>
              Agregar nueva venta
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import ListSale from "../../customers/ListSale";
export default {
  methods: {
    search() {
      console.log("search");
    }
  },
  props: ["windowHeight"],
  components: {
    AppListSale: ListSale
  }
};
</script>

<style></style>
