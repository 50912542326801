<template>
  <div class="pt-2">
    <v-card tile>
      <div class="text-h6 pt-4 pl-3">
        Productos agregados
      </div>
    </v-card>
    <v-card
      :height="windowHeight - 241 - 56 - 56 - 24 - 95"
      class="overflow-y-auto px-3"
    >
      <app-list-cart
        :elevationCard="0"
        :hideTitle="false"
        :productList="getCurrentCustomer.shoppingCartProductList"
      ></app-list-cart>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListCart from "../cart/List";

export default {
  computed: {
    ...mapGetters("pointSale", ["getCurrentCustomer"])
  },
  props: {
    windowHeight: {
      default: 300
    }
  },
  components: {
    AppListCart: ListCart
  }
};
</script>
