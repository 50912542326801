<template>
  <v-row>
    <v-card
      :disabled="totalProducts == 0"
      class="card-sale-status d-flex"
      height="50px"
      link
      width="100%"
      elevation="0"
      color="#ffffff00"
      @click="makeSale"
    >
      <v-card
        width="90px"
        color="success"
        class="d-flex align-center justify-center mr-1"
      >
        <v-chip color="white" class="font-weight-bold black--text">
          {{ totalProducts }}
        </v-chip>
      </v-card>
      <v-card
        width="100%"
        color="success"
        height="100%"
        class="d-flex align-center justify-center"
        dark
      >
        <div class="text-h6">Cobrar $ {{ totalToPay }}</div>
      </v-card>
    </v-card>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("pointSale", ["saveSaleInDatabase"]),
    makeSale() {
      this.saveSaleInDatabase();
    }
  },
  props: {
    totalProducts: {
      default: 0
    },
    totalToPay: {
      default: 0
    }
  }
};
</script>
