<template>
  <div>
    <app-quagga-scanner
      :onDetected="onDetectedIn"
      :manualDetected="findProduct"
    ></app-quagga-scanner>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuaggaScanner from "../../app/QuaggaScanner";
import { showMessage } from "@/services/MessageService";

export default {
  data() {
    return {
      timeOutBlock: true,
      onDetectedTime: new Date().getTime()
    };
  },
  components: {
    AppQuaggaScanner: QuaggaScanner
  },
  computed: {
    ...mapState("pointSale", ["productList"])
  },
  methods: {
    ...mapActions("pointSale", [
      "showCurrentProductSelected",
      "showSellAlert",
      "addProductToCart"
    ]),
    onDetectedIn(resultCode) {
      const now = new Date().getTime();
      if (now < this.onDetectedTime + 500) return;
      this.onDetectedTime = now;
      showMessage({
        message: `Código escaneado ${resultCode}`,
        color: "success",
        timeout: 2000
      });
      this.findProduct(resultCode);
    },
    findProduct(result) {
      const productFind = this.productList.filter(product => {
        return product.barcode == result;
      });
      if (productFind.length > 0) {
        this.addToCart(productFind[0]);
      } else {
        showMessage({
          message: `El código ${result} no fue encontrado en la lista de productos`,
          color: "error",
          timeout: 4000
        });
      }
    },
    addToCart(product) {
      this.showSellAnimation();
      this.showCurrentProductSelected(product);
      this.addProductToCart({ data: product, items: 1 });
    },
    showSellAnimation() {
      this.showSellAlert(true);
      setTimeout(() => {
        this.showSellAlert(false);
      }, 1500);
    }
  }
};
</script>
