<template>
  <v-card
    class="card-sale-status d-flex"
    height="50px"
    link
    width="94%"
    elevation="0"
    color="#ffffff00"
    @click="changeOption(3)"
  >
    <v-card
      width="90px"
      color="success"
      class="d-flex align-center justify-center mr-1"
    >
      <v-chip color="white" class="font-weight-bold black--text">
        {{ getCurrentCustomer.totalProducts }}
      </v-chip>
    </v-card>
    <v-card width="100%" elevation="0">
      <v-slide-y-transition origin="center" leave-absolute mode="in-out">
        <v-card
          width="100%"
          color="success"
          height="100%"
          class="d-flex align-center justify-center"
          dark
          v-if="!showSellProduct"
        >
          <div class="text-h6">Total $ {{ getCurrentCustomer.totalToPay }}</div>
        </v-card>
      </v-slide-y-transition>
      <v-slide-y-reverse-transition origin="center" leave-absolute>
        <v-card
          width="100%"
          height="100%"
          class="d-flex align-center justify-center"
          :dark="!$vuetify.theme.dark"
          :light="$vuetify.theme.dark"
          v-if="showSellProduct"
        >
          <div class="d-flex align-center">
            <v-avatar color="#e0e0e0" size="35" class="mr-2">
              <v-icon dark color="success">
                check_circle
              </v-icon>
            </v-avatar>
            <div :style="{ width: $vuetify.breakpoint.width - 170 + 'px' }">
              <p class="ma-0 text-truncate">
                {{ currentProductSelected.name_product }}
              </p>
              <p class="ma-0 caption text-truncate">
                {{ currentProductSelected.description }}
              </p>
            </div>
          </div>
        </v-card>
      </v-slide-y-reverse-transition>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState("pointSale", [
      "currentProductSelected",
      "showSellProduct",
      "actualCustomer",
      "footerOption"
    ]),
    ...mapGetters("pointSale", ["getCurrentCustomer"])
  },
  methods: {
    ...mapActions("pointSale", ["changeOptionFooterNavegation"]),
    changeOption(option) {
      this.changeOptionFooterNavegation(option);
    }
  }
};
</script>

<style scoped>
.card-sale-status {
  position: fixed;
  bottom: 0;
  right: 50%;
  z-index: 2;
  transform: translate(50%, -130%);
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
</style>
