<template>
  <v-container class="pt-0 pb-0" v-if="getCurrentCustomer">
    <app-shopping-total-to-pay
      class="mb-2"
      :totalToPay="getCurrentCustomer.totalToPay"
      :totalProducts="getCurrentCustomer.totalProducts"
      :customerName="getCurrentCustomer.name"
    ></app-shopping-total-to-pay>
    <app-shopping-list
      class="mb-2"
      :productList="getCurrentCustomer.shoppingCartProductList"
    ></app-shopping-list>
    <app-shopping-payment-method
      class="mb-2"
      :totalToPay="getCurrentCustomer.totalToPay"
      :ticketID="getCurrentCustomer.id"
    ></app-shopping-payment-method>
    <app-summary
      class="mb-2"
      :subTotal="getCurrentCustomer.subtotalPrice"
      :discount="
        getCurrentCustomer.subtotalPrice - getCurrentCustomer.totalToPay
      "
      :products="getCurrentCustomer.totalProducts"
      :totalToPay="getCurrentCustomer.totalToPay"
    ></app-summary>
    <app-buy-btn
      :totalToPay="getCurrentCustomer.totalToPay"
      :totalProducts="getCurrentCustomer.totalProducts"
    ></app-buy-btn>
  </v-container>
</template>

<script>
import ShoppingList from "./List";
import ShoppingTotalToPay from "./TotalToPay";
import ShoppingPaymentMethod from "./PaymentMethod";
import Summary from "./Summary";
import BuyBtn from "./BuyBtn";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("pointSale", ["getCurrentCustomer"])
  },
  components: {
    AppShoppingList: ShoppingList,
    AppShoppingTotalToPay: ShoppingTotalToPay,
    AppShoppingPaymentMethod: ShoppingPaymentMethod,
    AppSummary: Summary,
    AppBuyBtn: BuyBtn
  }
};
</script>

<style></style>
