<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item.photo="{ item }">
            <v-avatar size="35">
              <img :src="item.photo" alt="John" />
            </v-avatar>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 30,
      headers: [
        { text: "Imagen", value: "photo", align: "center" },
        { text: "Producto", value: "product", align: "center" },
        { text: "Precio", value: "price" },
        { text: "Inventario", value: "inventory" },
        { text: "SKU", value: "sku" }
      ],
      desserts: [
        {
          photo: "https://cdn.vuetifyjs.com/images/john.jpg",
          product: "Tela de maquillaje con mucho texto jaja si pero mas que ma",
          price: 133.2,
          inventory: 12,
          sku: "12312312"
        },
        {
          photo: "https://cdn.vuetifyjs.com/images/john.jpg",
          product: "Tela de maquillaje",
          price: 133.2,
          inventory: 12,
          sku: "12312312"
        }
      ]
    };
  }
};
</script>

<style></style>
