<template>
  <v-bottom-navigation app grow :value="footerOption" color="blue">
    <v-btn @click="changeOption(0)">
      <span>Lista</span>
      <v-icon>store</v-icon>
    </v-btn>
    <v-btn @click="changeOption(1)">
      <span>Scaner</span>
      <v-icon>qr_code_scanner</v-icon>
    </v-btn>
    <v-btn @click="changeOption(2)">
      <span>Tickets</span>
      <v-icon>receipt_long</v-icon>
    </v-btn>
    <v-btn @click="changeOption(3)">
      <span>Carrito</span>
      <v-icon>shopping_cart</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      option: 0
    };
  },
  methods: {
    ...mapActions("pointSale", ["changeOptionFooterNavegation"]),
    changeOption(option) {
      this.changeOptionFooterNavegation(option);
    }
  },
  computed: {
    ...mapState("pointSale", ["footerOption"])
  }
};
</script>

<style></style>
