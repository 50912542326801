<template>
  <v-row>
    <v-col cols="12" class="d-flex justify-space-between">
      <h2 class="text-h4 ma-0">Carrito</h2>
      <v-chip class="mt-1" small color="primary">
        {{ customerName }}
      </v-chip>
    </v-col>
    <v-list-item>
      <v-list-item-content class="pb-1">
        <v-list-item-title class="text-h5">
          Total: <span class="font-weight-bold">$ {{ totalToPay }}</span>
          <span class="caption ml-1">MXN</span>
        </v-list-item-title>
        <v-list-item-title class="text-subtitle-1 ml-1">
          {{ totalProducts | filterTotatlProducts }}
        </v-list-item-title>
        <div class="caption text--disabled">
          {{ dateToday | firstLetterUppercase }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dateToday: {
      type: String,
      default: () => moment().format("dddd DD [a las] h:mm A")
    },
    totalToPay: {
      type: Number,
      default: 0
    },
    totalProducts: {
      type: Number,
      default: 0
    },
    customerName: {
      default: ""
    }
  },
  filters: {
    filterTotatlProducts(value) {
      if (value === 1) return value + " producto";
      else return value + " productos";
    },
    firstLetterUppercase(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
</script>

<style></style>
