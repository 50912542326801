<template>
  <v-row>
    <v-container class="pl-0 pr-0">
      <div class="overline pt-1 pl-4">
        Productos
      </div>
      <v-divider></v-divider>
    </v-container>
    <v-list-item
      v-for="(n, index) in 20"
      :key="index + '-cart'"
      @click="toggleDialogEditProduct"
    >
      <v-list-item-avatar
        color="grey"
        tile
        class="mr-2"
        height="60px"
        width="60px"
      ></v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Titulo del producto</v-list-item-title>
        <v-list-item-title>29 x</v-list-item-title>
        <v-list-item-subtitle class="caption">$13.00</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content class="text-right max-width-price">
        <v-list-item-title>$ {{ n * 13.0 }}.00</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-row>
</template>

<script>
export default {};
</script>

<style>
.max-width-price {
  max-width: 90px !important;
}
</style>
