<template>
  <v-row>
    <v-card
      width="100%"
      class="mx-auto align-center card_background"
      ref="summarySection"
    >
      <div class="text-h6 pt-4 pl-3">
        Resumen
      </div>
      <v-divider></v-divider>
      <v-container class="px-6">
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between pt-1 pb-1">
            <div class="text-subtitle-2">Piezas Totales</div>
            <div class="text-subtitle-2 text--secondary">
              {{ products }}
            </div></v-col
          >
          <v-col cols="12" class="d-flex justify-space-between pt-1 pb-1">
            <div class="text-subtitle-2">Subtotal</div>
            <div class="text-subtitle-2 text--secondary">
              $ {{ subTotal }}
            </div></v-col
          >
          <v-col cols="12" class="d-flex justify-space-between pt-1 pb-1">
            <div class="text-subtitle-2">Descuento</div>
            <div class="text-subtitle-2 grey--text">
              - $ {{ discount }}
            </div></v-col
          >
          <v-col cols="12" class="d-flex justify-space-between  pt-1 pb-1">
            <div class="text-subtitle-2">Total</div>
            <div class="text-body-1 font-weight-bold">
              $ {{ totalToPay }}
            </div></v-col
          >
        </v-row>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
export default {
  props: {
    subTotal: {
      type: Number,
      default: 0
    },
    discount: {
      type: Number,
      default: 0
    },
    totalToPay: {
      type: Number,
      default: 0
    },
    products: {
      default: 0
    }
  }
};
</script>

<style></style>
