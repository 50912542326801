<template>
  <v-row>
    <v-card width="100%" class="mx-auto card_background" ref="paymentSection">
      <div class="text-h6 pt-4 pl-3">Metodo de Pago</div>
      <v-divider></v-divider>
      <v-container class="pt-0 pb-0">
        <v-row>
          <v-col cols="12">
            <v-expansion-panels flat mandatory @change="changePaymentMethod">
              <v-expansion-panel
                :key="0"
                style="border: 1px solid rgba(0, 0, 0, 0.12);"
              >
                <v-expansion-panel-header expand-icon="" class="px-3">
                  <v-radio-group
                    :value="paymentMethod === 0 ? 0 : null"
                    hide-details
                    class="pa-0 ma-0 d-flex"
                  >
                    <v-radio label="Efectivo" readonly></v-radio>
                  </v-radio-group>
                  <p class="text-right ma-0">
                    <v-icon color="green">payments</v-icon>
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <p class="ma-0 caption">
                      Ingresa una cantidad de dinero para calcular el cambio
                    </p>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Cantidad"
                        placeholder="0.00"
                        outlined
                        hide-details
                        type="number"
                        v-model="payWith"
                        @click="payWithFocus('paymentSection')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="payWith"
                      cols="12"
                      class="text-center pt-0 pb-0"
                    >
                      <div class="text-subtitle-1">
                        Cambio
                      </div>
                      <div>
                        <span class="font-weight-bold text-h6"
                          >$ {{ exchange }}</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                :key="1"
                style="border: 1px solid rgba(0, 0, 0, 0.12);"
              >
                <v-expansion-panel-header
                  expand-icon=""
                  class="px-3"
                  @click="payWithFocus('paymentSection')"
                >
                  <v-radio-group
                    readonly
                    :value="paymentMethod === 1 ? 0 : null"
                    hide-details
                    class="pa-0 ma-0 d-flex"
                  >
                    <v-radio
                      label="Tarjeta de Crédito o Débito"
                      readonly
                    ></v-radio>
                  </v-radio-group>
                  <p class="text-right ma-0">
                    <v-icon color="#c5a237">credit_card</v-icon>
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="d-flex justify-center mb-3">
                    <div class="d-flex" style="width: 212px">
                      <v-img
                        class="mr-2"
                        src="../../../assets/point-sale/mp.svg"
                        contain
                      ></v-img>
                      <v-img
                        class="mx-2"
                        :class="{ grey: !$vuetify.theme.dark }"
                        src="../../../assets/point-sale/visa.svg"
                        contain
                      ></v-img>
                      <v-img
                        class="mx-2"
                        src="../../../assets/point-sale/mastercard.svg"
                        contain
                      ></v-img>
                      <v-img
                        class="mx-2"
                        src="../../../assets/point-sale/american-express.svg"
                        contain
                      ></v-img>
                      <v-img
                        class="ml-2"
                        src="../../../assets/point-sale/mercado-credits.svg"
                        contain
                      ></v-img>
                    </div>
                  </div>
                  <div>
                    <p class="ma-0 caption">
                      Para realizar cobros con tarjetas usa tu lector Point (Ten
                      en cuenta que para poder realizar en enlace atomatico
                      debera tener instala la aplicación de Mercado Pago en tu
                      celular)
                    </p>
                  </div>
                  <div class="text-center mt-2">
                    <v-btn
                      class="pulse-on"
                      height="130"
                      width="130"
                      text
                      :href="urlBluePoint"
                      @click="bluePointMethod"
                      target="_blank"
                      :disabled="totalToPay <= 0"
                      ><v-img
                        src="../../../assets/point-sale/blue-point-ML.png"
                        height="100"
                        contain
                      ></v-img
                    ></v-btn>
                  </div>
                  <!-- <div>
                    <p class="ma-0 caption">
                      Para realizar cobros mediante códigos QR pide al cliente
                      escanear el código que se generara (en desarrollo)*
                    </p>
                  </div>
                  <div class="text-center">
                    <v-btn height="130" width="130" text disabled
                      ><v-img
                        src="../../../assets/point-sale/QR-ML.png"
                        height="100"
                        contain
                      ></v-img
                    ></v-btn>
                  </div> -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { app } from "@/config/globals";
export default {
  data() {
    return {
      sendEmailCustomer: false,
      payWith: null,
      urlBluePoint: `https://www.mercadopago.com/point/integrations?amount=${this.totalToPay}&description=${this.ticketID}&success_url="http://${app.url}/punto-venta"&fail_url="http://${app.url}/punto-venta"`
    };
  },
  props: {
    ticketID: {
      required: true
    },
    totalToPay: {
      type: Number,
      required: true
    },
    heightBtn: {
      type: String,
      default: "130px"
    },
    widthBtn: {
      type: String,
      default: "40%"
    },
    noPaddingContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("pointSale", ["paymentMethod"]),
    exchange() {
      if (this.payWith >= this.totalToPay)
        return this.payWith - this.totalToPay;
      return 0;
    }
  },
  methods: {
    ...mapActions("pointSale", ["setPaymentMethod"]),
    payWithFocus(paymentSection) {
      setTimeout(() => {
        this.$vuetify.goTo(this.$refs[paymentSection]);
      }, 100);
    },
    bluePointMethod() {
      const totalH = document.body.scrollHeight;
      this.$vuetify.goTo(totalH);
    },
    changePaymentMethod(value) {
      this.setPaymentMethod(value);
    }
  }
};
</script>

<style>
.no-box-shadow::before {
  box-shadow: unset !important;
}
.no-box-shadow {
  box-shadow: unset !important;
}
</style>

<style scoped>
.pulse-on {
  animation: shadow-pulse 1.5s infinite;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
