<template>
  <v-card class="mx-auto pb-4" :elevation="0">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="6">
          <v-text-field
            ref="searchInput"
            :hide-details="true"
            outlined
            dense
            prepend-inner-icon="search"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pr-0">
          <div class="d-flex align-center justify-end">
            <v-btn dark color="secondary" class="mr-2">
              <v-icon dark class="mr-2">flash_on</v-icon> Fast
            </v-btn>
            <v-btn color="secondary" dark @click="toggleViewList()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-chip class="ma-2" color="primary">
              <v-avatar left>
                <v-icon>person</v-icon>
              </v-avatar>
              Edwin
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs grow>
      <v-tab>Productos</v-tab>
      <v-tab>Clientes</v-tab>

      <v-tab-item :key="0">
        <Product />
      </v-tab-item>
      <v-tab-item :key="1">
        <Customer />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import Product from "./point-sale-tabs/Product";
import Customer from "./point-sale-tabs/Customer";

export default {
  props: {
    uid: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {};
  },
  components: {
    Product,
    Customer
  }
};
</script>
