<template>
  <v-dialog
    v-model="dialogProductDetail"
    max-width="360"
    width="360"
    persistent
  >
    <v-card class="card_background">
      <v-app-bar
        color="card_background"
        elevation="0"
        width="326px"
        height="56"
        style="position:fixed; z-index:10; transform: translateX(-50%); left: 50%"
      >
        <span class="text-h6">Detalle</span>
        <v-spacer></v-spacer>

        <v-btn icon @click="closeDetail"><v-icon>close</v-icon></v-btn>
      </v-app-bar>
      <div style="height: 43px"></div>
      <v-container>
        <div class="text-center mb-2">
          <v-img
            :src="currentDetailProduct.photoURL"
            v-if="currentDetailProduct.photoURL"
            height="150"
            contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
        <v-card outlined>
          <v-container class="py-1">
            <div class="ma-0 caption">
              Código:
              <span class="text-subtitle-1">{{
                currentDetailProduct.barcode
              }}</span>
            </div>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1">
          <v-container class="py-1">
            <div class="ma-0 caption text--secondary">Nombre</div>
            <div class="ma-0 mb-2">{{ currentDetailProduct.name_product }}</div>
            <div class="ma-0 caption text--secondary">Descripción</div>
            <div class="ma-0 mb-2">{{ currentDetailProduct.description }}</div>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1">
          <v-container class="py-1">
            <div class="d-flex justify-space-between mb-2">
              <div>
                <p class="ma-0 caption text--secondary">Precio al publico</p>
                <p class="ma-0 text-h6">$ {{ currentDetailProduct.price }}</p>
              </div>
              <div class="text-right">
                <p class="ma-0 caption text--secondary">Precio costo</p>
                <p class="ma-0 text-h6">
                  $ {{ currentDetailProduct.cost_price }}
                </p>
              </div>
            </div>
          </v-container>
        </v-card>
        <v-card
          outlined
          class="mt-1"
          v-if="currentDetailProduct.total_inventory != null"
        >
          <v-container class="py-1">
            <p class="my-1 text-center">Inventario</p>
            <div class="d-flex justify-space-between mb-2">
              <div>
                <p class="ma-0 caption text--secondary">Actual</p>
                <p class="ma-0">
                  <span class="text-h6">{{
                    currentDetailProduct.total_inventory
                  }}</span
                  >{{ currentDetailProduct.total_inventory | isInventoried }}
                </p>
              </div>
              <div class="text-right">
                <p class="ma-0 caption text--secondary">Cantidad mínima</p>
                <p class="ma-0">
                  <span class="text-h6">{{
                    currentDetailProduct.min_inventory
                  }}</span>
                </p>
              </div>
            </div>
            <div
              class="mt-1"
              v-if="getObjectSize(currentDetailProduct.branch_office) > 1"
            >
              <p class="text-center text--disabled caption mt-1 ma-0">
                Disponibilidad en otras sucursales
              </p>
              <v-container
                class="pa-1"
                v-for="(branch,
                key,
                index) in currentDetailProduct.branch_office"
                :key="index + '-brnach'"
              >
                <v-card
                  outlined
                  class="px-2 py-1"
                  v-if="key != currentBranchID"
                >
                  <p class="text-center mb-0">{{ branch.name }}</p>
                  <div class="d-flex justify-space-between align-center">
                    <p class="ma-0 caption text--secondary">
                      Inventario actual:
                    </p>
                    <p class="ma-0 text-subtitle-1">
                      {{ branch.inventory.total_inventory }}
                      <span class="caption">{{
                        branch.inventory.total_inventory | isInventoried
                      }}</span>
                    </p>
                  </div>
                </v-card>
              </v-container>
            </div>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1" v-if="currentDetailProduct.wholesale">
          <v-container class="py-1">
            <div class="text-center mb-2">
              <span>Mayoreo</span>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="ma-0 caption text--secondary">Precio de Mayoreo</p>
              <p class="ma-0 caption text--secondary">Min. Mayoreo</p>
            </div>
            <template
              v-for="(wholesale, index) in currentDetailProduct.wholesale"
            >
              <div
                :key="index + '-wholesale-detail'"
                class="d-flex justify-space-between mb-1"
                v-if="currentDetailProduct.wholesale"
              >
                <p
                  class="ma-0"
                  v-html="
                    wholesaleDiscount(
                      wholesale.wholesale_price,
                      currentDetailProduct.price
                    )
                  "
                ></p>
                <p class="ma-0">
                  <span class="font-weight-medium">{{
                    wholesale.minimum_products
                  }}</span>
                  <span class="caption">{{
                    wholesale.minimum_products | isInventoried
                  }}</span>
                </p>
              </div>
            </template>
          </v-container>
        </v-card>
        <v-card outlined class="mt-1 mb-2">
          <v-container class="pt-0">
            <p class="ma-0 caption text--secondary mt-2">
              Agrega este producto al carrito
            </p>
            <div class="d-flex align-center justify-center mt-1 mb-2">
              <div style="width: 120px" class="d-flex align-center">
                <v-btn icon @click="totalProducts--">
                  <v-icon>remove</v-icon>
                </v-btn>
                <v-text-field
                  class="centered-input"
                  v-model="totalProducts"
                  hide-details
                  outlined
                  dense
                  type="number"
                ></v-text-field>
                <v-btn icon @click="totalProducts++">
                  <v-icon>add</v-icon>
                </v-btn>
              </div>
            </div>
            <v-btn
              block
              color="success"
              small
              @click="addToCart(currentDetailProduct)"
              >Agregar producto</v-btn
            >
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EventBus from "@/config/bus";
export default {
  data() {
    return {
      totalProducts: 1,
      dialogProductDetail: false
    };
  },
  watch: {
    totalProducts(updateTotalProducts) {
      if (updateTotalProducts < 1) {
        this.totalProducts = 1;
      }
    }
  },
  mounted() {
    EventBus.$on("TOGGLE_DIALOG_DETAIL_POINTSALE", () => {
      this.dialogProductDetail = !this.dialogProductDetail;
    });
  },
  computed: {
    ...mapState("pointSale", ["currentDetailProduct"]),
    ...mapState("business", ["currentBranchID"])
  },
  methods: {
    ...mapActions("pointSale", [
      "viewDetailProduct",
      "showCurrentProductSelected",
      "showSellAlert",
      "addProductToCart"
    ]),
    addToCart(product) {
      this.showSellAnimation();
      this.showCurrentProductSelected(product);
      this.addProductToCart({ data: product, items: this.totalProducts });
      this.closeDetail();
    },
    showSellAnimation() {
      this.showSellAlert(true);
      setTimeout(() => {
        this.showSellAlert(false);
      }, 1500);
    },
    closeDetail() {
      this.dialogProductDetail = false;
      setTimeout(() => {
        this.viewDetailProduct({});
      }, 300);
      this.totalProducts = 1;
    },
    wholesaleDiscount(wholesale_price, price) {
      const totalPrice = parseFloat(price);
      const wholesalePriceByUnit = parseFloat(wholesale_price);
      const wholesalePrice = wholesalePriceByUnit * 100;
      const discount = 100 - wholesalePrice / totalPrice;
      return `$ <span class="font-weight-medium"> ${wholesalePriceByUnit}</span> <span class="caption text--secondary ml-2"> ${parseFloat(
        discount
      ).toFixed(1)}% Descuento </span> `;
    },
    getObjectSize(obj) {
      return Object.keys(obj).length;
    }
  },
  filters: {
    isInventoried(inventory) {
      if (inventory != null) {
        if (inventory === 1) return " Pieza";
        else return " Piezas";
      } else {
        return "N/A";
      }
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
