<template>
  <v-container class="pt-0 pb-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="8" xl="9" class="pb-0">
        <v-card>
          <v-responsive height="85vh" class="overflow-y-auto">
            <app-list></app-list>
          </v-responsive>
        </v-card>
        <v-card class="mt-2">
          <v-container>
            <v-text-field
              :hide-details="true"
              outlined
              filled
              dense
              prepend-inner-icon="qr_code_scanner"
            ></v-text-field>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="3" class="pr-0 pl-0 pb-0">
        <v-container class="pt-0 pb-0">
          <v-container class="pb-0 pt-0" ref="shoppingComponent">
            <app-shopping-total-to-pay class="mb-2"></app-shopping-total-to-pay>
          </v-container>
          <v-card width="100%" class="mx-auto">
            <v-responsive
              :style="{ height: 'calc(100vh - ' + heightSummary + 'px)' }"
              width="100%"
              class="overflow-y-auto mt-2 mb-0"
            >
              <v-container class="pt-0 pb-0">
                <app-shopping-list class="mb-2"></app-shopping-list>
              </v-container>
            </v-responsive>
          </v-card>
          <v-container ref="summaryComponent">
            <app-summary class="mb-2"></app-summary>
            <app-buy-btn></app-buy-btn>
          </v-container>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListDesktop from "@/components/point-of-sale/desktop/ListDesktop";
import ShoppingTotalToPay from "@/components/point-of-sale/cart/TotalToPay";
import Summary from "@/components/point-of-sale/cart/Summary";
import BuyBtn from "@/components/point-of-sale/cart/BuyBtn";

import List from "@/components/point-of-sale/desktop/PointSaleContent";

export default {
  data() {
    return {
      heightSummary: 0
    };
  },
  components: {
    AppShoppingList: ListDesktop,
    AppShoppingTotalToPay: ShoppingTotalToPay,
    AppSummary: Summary,
    AppBuyBtn: BuyBtn,

    AppList: List
  },
  mounted() {
    this.heightSummary =
      this.$refs.shoppingComponent.clientHeight +
      this.$refs.summaryComponent.clientHeight +
      55;
  }
};
</script>
