<template>
  <v-container class="pt-0 pb-0">
    <v-row>
      <v-card
        width="100%"
        :min-height="windowHeight - 56 - 56 - 24"
        class="card_background"
      >
        <v-container class="d-flex justify-space-between align-center pb-1">
          <div class="text-h5 pl-2">
            Tickets
          </div>
          <v-chip class="ma-2" small v-if="getCurrentCustomer">
            <v-avatar left>
              <v-icon small>receipt</v-icon>
            </v-avatar>
            {{ getCurrentCustomer.name }}
          </v-chip>
        </v-container>
        <v-container class="pt-0 pb-0">
          <app-list-sale></app-list-sale>
        </v-container>
        <v-container class="pt-0">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn @click="addCustomer" color="primary">
                Agregar nuevo Ticket
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListSale from "./ListSale";
export default {
  methods: {
    ...mapActions("pointSale", ["addCustomer"])
  },
  computed: {
    ...mapGetters("pointSale", ["getCurrentCustomer"])
  },
  props: ["windowHeight"],
  components: {
    AppListSale: ListSale
  }
};
</script>

<style></style>
