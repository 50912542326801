<style>
.scanner-container.viewport {
  width: 100%;
  height: 180px;
  overflow: hidden;
}
.scanner-container.viewport {
  position: relative;
}
.scanner-container.viewport > canvas,
.scanner-container.viewport > video {
  max-width: 100%;
  width: 100%;
}
#interactive.viewport > canvas,
#interactive.viewport > video {
  max-width: 100%;
  width: 100%;
}
canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
</style>

<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          left
          absolute
          fab
          v-bind="attrs"
          color="#27272791"
          class="absolute-device-camera"
          v-on="on"
          dark
          :loading="loadingDevices"
          small
        >
          <v-icon>camera_front</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(device, index) in devices" :key="index">
          <v-list-item-title
            :class="{ whiteOrBlackText }"
            @click="selectedDevice(device)"
            >{{ device.label }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      absolute
      right
      fab
      color="#27272791"
      class="absolute-device-camera-code"
      dark
      @click="dialogCodes = true"
      :loading="loadingDevices"
      small
    >
      <v-icon>qr_code_2</v-icon>
    </v-btn>
    <div id="interactive" class="viewport scanner scanner-container">
      <video
        style=" position: absolute;top: 50%; right: 50%;transform: translate(50%,-50%);"
      ></video>
    </div>
    <v-text-field
      hide-details
      outlined
      type="text"
      dense
      filled
      class="mt-2"
      append-icon="qr_code"
      v-model="inputCode"
      @click:append="searchProduct"
    ></v-text-field>
    <v-dialog v-model="dialogCodes" width="350">
      <v-card class="card_background">
        <v-app-bar
          elevation="1"
          width="326px"
          height="56"
          style="position:fixed; z-index:10; transform: translateX(-50%); left: 50%"
        >
          <span class="text-h6">Código</span>
          <v-spacer></v-spacer>

          <v-btn icon @click="dialogCodes = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-app-bar>
        <div style="height: 63px"></div>
        <template v-for="(decoder, index) in decoderList">
          <v-card
            @click="selectedCode(decoder.code)"
            :key="index + '-code'"
            class="d-flex justify-center ma-3 mt-0 py-2"
            outlined
          >
            <div>
              <v-img :src="decoder.photo" contain width="200px"></v-img>
            </div>
            <div
              v-if="quaggaState"
              style="position: absolute; right:5px; top:7px"
            >
              <div v-if="quaggaState.decoder">
                <v-chip
                  small
                  color="primary"
                  class="caption"
                  v-if="quaggaState.decoder.readers[0] === decoder.code"
                  >Activo</v-chip
                >
              </div>
            </div>
          </v-card>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Quagga from "@ericblade/quagga2"; // v2
import { loaderDone, loaderStart } from "@/services/LoaderService";
import { errorMessage, showMessage } from "@/services/MessageService";
import { toggleLoader } from "@/services/DialogsBus";
import { mapState } from "vuex";

export default {
  data() {
    return {
      onDetectedTime: new Date().getTime(),
      dialogCodes: false,
      decoderList: [
        {
          code: "ean_reader",
          name: "Code EAN",
          photo: require("@/assets/barcode/ean_reader.gif")
        },
        {
          code: "code_128_reader",
          name: "Code 128",
          photo: require("@/assets/barcode/code_128_reader.gif")
        },
        {
          code: "ean_8_reader",
          name: "Code EAN 8",
          photo: require("@/assets/barcode/ean_8_reader.gif")
        },
        {
          code: "code_39_reader",
          name: "Code 39",
          photo: require("@/assets/barcode/code_39_reader.gif")
        },
        {
          code: "code_39_vin_reader",
          name: "Code 39 VIN",
          photo: require("@/assets/barcode/code_39_vin_reader.png")
        },
        {
          code: "codabar_reader",
          name: "Code Codabar",
          photo: require("@/assets/barcode/codabar_reader.jpg")
        },
        {
          code: "upc_reader",
          name: "Code UPC",
          photo: require("@/assets/barcode/upc_reader.gif")
        },
        {
          code: "upc_e_reader",
          name: "Code UPC-E",
          photo: require("@/assets/barcode/upc_e_reader.gif")
        },
        {
          code: "i2of5_reader",
          name: "Code i2of5",
          photo: require("@/assets/barcode/i2of5_reader.jpg")
        },
        {
          code: "2of5_reader",
          name: "Code 2of5",
          photo: require("@/assets/barcode/2of5_reader.jpg")
        },
        {
          code: "code_93_reader",
          name: "Code 93",
          photo: require("@/assets/barcode/code_93_reader.gif")
        }
      ],
      inputCode: null,
      devices: [],
      loadingDevices: false,
      quaggaState: {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            facingMode: "environment",
            aspectRatio: {
              min: 1,
              max: 2
            }
          },
          area: {
            top: "0%",
            right: "0%",
            left: "0%",
            bottom: "0%"
          }
        },
        frequency: 10,
        decoder: {
          readers: [this.readerType]
        }
      }
    };
  },
  mounted() {
    if (this.footerOption === 1) this.init();
  },
  watch: {
    footerOption(newValue, oldValue) {
      if (newValue === 1) {
        this.init();
      } else {
        if (oldValue === 1) {
          Quagga.stop();
          this.destoryData();
        }
      }
    }
  },
  beforeDestroy() {
    this.stopDecoder();
  },
  computed: {
    ...mapState("pointSale", ["footerOption"]),
    whiteOrBlackText() {
      return this.$vuetify.theme.dark ? "white--text" : "black--text";
    }
  },
  methods: {
    init() {
      loaderStart();
      this.loadingDevices = true;
      Quagga.init(this.quaggaState, err => {
        if (err) {
          loaderDone();
          toggleLoader();
          return errorMessage({
            message: "Ocurrio un error: " + err
          });
        }
        this.checkCapabilities();
        this.getDevices();
        Quagga.start();
        loaderDone();
        toggleLoader();
      });
      Quagga.onDetected(result => {
        const now = new Date().getTime();
        if (now < this.onDetectedTime + 2200) return;
        this.onDetectedTime = now;
        this.onDetected(result.codeResult.code);
      });
    },
    searchProduct() {
      this.manualDetected(this.inputCode);
    },
    reInit() {
      Quagga.stop();
      this.init();
    },
    stopDecoder() {
      Quagga.stop();
    },
    selectedCode(code) {
      this.quaggaState.decoder.readers = [code];
      showMessage({
        message: `Se cambio el código a ${code}`,
        color: "info",
        timeout: 4000
      });
      this.reInit();
    },
    selectedDevice(device) {
      this.quaggaState.inputStream.constraints.deviceId = device.deviceId;
      showMessage({
        message: `Estas utilizando el dispositivo ${device.label}`,
        color: "info",
        timeout: 4000
      });
      this.reInit();
    },
    getDevices() {
      Quagga.CameraAccess.enumerateVideoDevices().then(devices => {
        this.devices = devices;
        this.loadingDevices = false;
      });
    },
    checkCapabilities() {
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      if (capabilities.zoom) {
        const maxZoom = parseFloat(capabilities.zoom.max / 3).toFixed(1);
        track.applyConstraints({
          advanced: [{ zoom: maxZoom }]
        });
      }
    },
    destoryData() {
      (this.onDetectedTime = null),
        (this.dialogCodes = false),
        (this.decoderList = [
          {
            code: "ean_reader",
            name: "Code EAN",
            photo: require("@/assets/barcode/ean_reader.gif")
          },
          {
            code: "code_128_reader",
            name: "Code 128",
            photo: require("@/assets/barcode/code_128_reader.gif")
          },
          {
            code: "ean_8_reader",
            name: "Code EAN 8",
            photo: require("@/assets/barcode/ean_8_reader.gif")
          },
          {
            code: "code_39_reader",
            name: "Code 39",
            photo: require("@/assets/barcode/code_39_reader.gif")
          },
          {
            code: "code_39_vin_reader",
            name: "Code 39 VIN",
            photo: require("@/assets/barcode/code_39_vin_reader.png")
          },
          {
            code: "codabar_reader",
            name: "Code Codabar",
            photo: require("@/assets/barcode/codabar_reader.jpg")
          },
          {
            code: "upc_reader",
            name: "Code UPC",
            photo: require("@/assets/barcode/upc_reader.gif")
          },
          {
            code: "upc_e_reader",
            name: "Code UPC-E",
            photo: require("@/assets/barcode/upc_e_reader.gif")
          },
          {
            code: "i2of5_reader",
            name: "Code i2of5",
            photo: require("@/assets/barcode/i2of5_reader.jpg")
          },
          {
            code: "2of5_reader",
            name: "Code 2of5",
            photo: require("@/assets/barcode/2of5_reader.jpg")
          },
          {
            code: "code_93_reader",
            name: "Code 93",
            photo: require("@/assets/barcode/code_93_reader.gif")
          }
        ]),
        (this.inputCode = null),
        (this.devices = []),
        (this.loadingDevices = false),
        (this.quaggaState = {
          inputStream: {
            name: "Live",
            type: "LiveStream",
            constraints: {
              facingMode: "environment",
              aspectRatio: {
                min: 1,
                max: 2
              }
            },
            area: {
              top: "0%",
              right: "0%",
              left: "0%",
              bottom: "0%"
            }
          },
          frequency: 10,
          decoder: {
            readers: [this.readerType]
          }
        });
    }
  },
  props: {
    onDetected: {
      type: Function
    },
    onProcessed: {
      type: Function
    },
    manualDetected: {
      type: Function
    },
    readerType: {
      type: String,
      default: "ean_reader"
    }
  }
};
</script>
